<template>
    <div class="ui__logos">
        <Carousel :value="items" :numVisible="5" :numScroll="1" :responsiveOptions="responsiveOptions" :autoplayInterval="autoplayInterval" circular ref="logos">
            <template #header>
                <h3 class="text-center">A history of trust by leading organizations</h3>
            </template>
            <template #item="{ data }">
                <img :src="data.logo" class="m-auto" v-tooltip.top="data.name">
            </template>
        </Carousel>
    </div>
</template>

<script>
    import Carousel from 'primevue/carousel'
    export default {
        components: {
            Carousel
        },
        data() {
            return {
                items: [
                    { name: 'Alive and Kickin\' Pizza Crust', logo: '/images/welcome/logos/AK-Crust_logo.png' },
                    { name: 'Baisch Engineering', logo: '/images/welcome/logos/Baisch-Engineering_logo.png' },
                    { name: 'Boldt Construction', logo: '/images/welcome/logos/Boldt_logo.png' },
                    { name: 'Charcuterie Artisans', logo: '/images/welcome/logos/Charcuterie-Artisans_logo.png' },
                    { name: 'Kraft Foods, Inc.', logo: '/images/welcome/logos/Kraft_logo.png' },
                    { name: 'The Meat Block', logo: '/images/welcome/logos/Meat-Block_logo.png' },
                    { name: 'Monterey Mushrooms', logo: '/images/welcome/logos/Monterey-Mushrooms_logo.png' },
                    { name: 'Nicolet National Bank', logo: '/images/welcome/logos/Nicolet-Bank_logo.png' },
                    { name: 'St. Norbert College', logo: '/images/welcome/logos/St-Norbert_logo.png' },
                    { name: 'Truck Equipment, Inc.', logo: '/images/welcome/logos/Truck-Equipment_logo.png' },
                    { name: 'University of Green Bay', logo: '/images/welcome/logos/UW-Green-Bay_logo.png' },
                ],
                responsiveOptions: [
                    {
                        breakpoint: '9999px',
                        numVisible: 10,
                        numScroll: 1
                    },
                    {
                        breakpoint: '2199px',
                        numVisible: 9,
                        numScroll: 1
                    },
                    {
                        breakpoint: '1999px',
                        numVisible: 8,
                        numScroll: 1
                    },
                    {
                        breakpoint: '1799px',
                        numVisible: 7,
                        numScroll: 1
                    },
                    {
                        breakpoint: '1599px',
                        numVisible: 6,
                        numScroll: 1
                    },
                    {
                        breakpoint: '1399px',
                        numVisible: 5,
                        numScroll: 1
                    },
                    {
                        breakpoint: '1199px',
                        numVisible: 4,
                        numScroll: 1
                    },
                    {
                        breakpoint: '991px',
                        numVisible: 3,
                        numScroll: 1
                    },
                    {
                        breakpoint: '767px',
                        numVisible: 2,
                        numScroll: 1
                    },
                    {
                        breakpoint: '479px',
                        numVisible: 1,
                        numScroll: 1
                    }
                ]
            }
        },
        computed: {
            autoplayInterval() {
                // only auto-scroll small screens
                if(window.matchMedia('(min-width: 992px)').matches) return 0
                return 3000
            }
        }
    }
</script>

<style lang="scss">
    .ui__logos .p-carousel-item-list {
        .p-carousel-item {
            padding: 2rem;
            display: flex;
            align-items: center;
            img {
                max-width: 140px;
                max-height: 100px;
                filter: grayscale(0);
                opacity: 1;
                transition: all 0.25s ease;
                &:hover {
                    opacity: 0.25;
                    filter: grayscale(100%);
                }
            }
        }
    }
</style>